export default defineNuxtRouteMiddleware(async (to) => {

  const targetRoute:string = to.path

  const projectRoutes:RegExp[] = [
    /^\/$/,
    /\/404/,
    /\/login/,
    /\/remember/,
    /\/meta(.*)/,
    /\/bea(.*)/,
    /\/config(.*)/
  ]

  if (!projectRoutes.some((re:RegExp) => re.test(targetRoute))) {
    return navigateTo('/404')
  }

})
