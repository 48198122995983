import { default as _404aK5ZXnFtLvMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/404.vue?macro=true";
import { default as indexuvtVKloQIdMeta } from "/Users/adria/FSBD/Projects-Hub/app-data/pages/bea/NIPA/index.vue?macro=true";
import { default as index8Z5JrphMOxMeta } from "/Users/adria/FSBD/Projects-Hub/app-data/pages/bea/NIUnderlyingDetail/index.vue?macro=true";
import { default as indexeZ0HVRFGnjMeta } from "/Users/adria/FSBD/Projects-Hub/app-data/pages/bea/Regional/index.vue?macro=true";
import { default as _91id_93sH2WB01ShbMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/briefings/[product]/[briefing]/[id].vue?macro=true";
import { default as createrXqfhm73FcMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/briefings/create.vue?macro=true";
import { default as indexrxyZebESEFMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/briefings/index.vue?macro=true";
import { default as _91_46_46_46slug_93SXTFfBKDJpMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/companies/[...slug].vue?macro=true";
import { default as createlz2BXubK76Meta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/companies/create.vue?macro=true";
import { default as indexs8Oq7AJW2wMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/companies/index.vue?macro=true";
import { default as createg53bf8OlXwMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/invoices/create.vue?macro=true";
import { default as indexjVXJZDEqDwMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/invoices/index.vue?macro=true";
import { default as _91_46_46_46slug_932PBsLztlOhMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/plans/[...slug].vue?macro=true";
import { default as createHFfNEWY8oSMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/plans/create.vue?macro=true";
import { default as indexwnyDKF2TaVMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/plans/index.vue?macro=true";
import { default as _91_46_46_46slug_932lAyN1acYmMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/products/[...slug].vue?macro=true";
import { default as create65TMd05tktMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/products/create.vue?macro=true";
import { default as index9u07utPeu5Meta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/products/index.vue?macro=true";
import { default as _91_46_46_46slug_93ML6Mh5wySOMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/signups/[...slug].vue?macro=true";
import { default as createlUrqPnNUetMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/signups/create.vue?macro=true";
import { default as indexc9YHqcJOMiMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/signups/index.vue?macro=true";
import { default as _91_46_46_46slug_93OPQd2BtJoOMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/users/[...slug].vue?macro=true";
import { default as createBOMOQfiVrLMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/users/create.vue?macro=true";
import { default as indexAG9NmVUCdmMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/users/index.vue?macro=true";
import { default as indexMSJURGLlPWMeta } from "/Users/adria/FSBD/Projects-Hub/app-data/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93xQnNiKqXdFMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/insights/[...slug].vue?macro=true";
import { default as indexBAEZIuzuPgMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/insights/activate/index.vue?macro=true";
import { default as createkEX3hEWwspMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/insights/create.vue?macro=true";
import { default as index6pxXvSbG8bMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/insights/index.vue?macro=true";
import { default as loginKxKexOSYh0Meta } from "/Users/adria/FSBD/Projects-Hub/app-data/pages/login.vue?macro=true";
import { default as processLo66b6hy4eMeta } from "/Users/adria/FSBD/Projects-Hub/app-data/pages/meta/audiences/process.vue?macro=true";
import { default as requestJnXVRTQONSMeta } from "/Users/adria/FSBD/Projects-Hub/app-data/pages/meta/audiences/request.vue?macro=true";
import { default as indexr8VqBB2ifWMeta } from "/Users/adria/FSBD/Projects-Hub/app-data/pages/meta/interests/index.vue?macro=true";
import { default as suggestionsincRXEqSe8Meta } from "/Users/adria/FSBD/Projects-Hub/app-data/pages/meta/interests/suggestions.vue?macro=true";
import { default as _91_46_46_46slug_93hzdPIiIGwiMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/plannings/[...slug].vue?macro=true";
import { default as createWmJJ4UhEi9Meta } from "/Users/adria/FSBD/Projects-Hub/app/pages/plannings/create.vue?macro=true";
import { default as indexoNIlnIsOdSMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/plannings/index.vue?macro=true";
import { default as privacy5aXkPHeJFnMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/privacy.vue?macro=true";
import { default as _91_46_46_46slug_93WQ6uuLUEveMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/products/[...slug].vue?macro=true";
import { default as indexWtmDvk6ROHMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/products/index.vue?macro=true";
import { default as rememberIeVmnrfKBdMeta } from "/Users/adria/FSBD/Projects-Hub/app-data/pages/remember.vue?macro=true";
import { default as termsgcHdUAmUgoMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/terms.vue?macro=true";
export default [
  {
    name: "404",
    path: "/404",
    meta: _404aK5ZXnFtLvMeta || {},
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "bea-NIPA",
    path: "/bea/NIPA",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app-data/pages/bea/NIPA/index.vue").then(m => m.default || m)
  },
  {
    name: "bea-NIUnderlyingDetail",
    path: "/bea/NIUnderlyingDetail",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app-data/pages/bea/NIUnderlyingDetail/index.vue").then(m => m.default || m)
  },
  {
    name: "bea-Regional",
    path: "/bea/Regional",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app-data/pages/bea/Regional/index.vue").then(m => m.default || m)
  },
  {
    name: "briefings-product-briefing-id",
    path: "/briefings/:product()/:briefing()/:id()",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/briefings/[product]/[briefing]/[id].vue").then(m => m.default || m)
  },
  {
    name: "briefings-create",
    path: "/briefings/create",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/briefings/create.vue").then(m => m.default || m)
  },
  {
    name: "briefings",
    path: "/briefings",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/briefings/index.vue").then(m => m.default || m)
  },
  {
    name: "config-companies-slug",
    path: "/config/companies/:slug(.*)*",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/companies/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "config-companies-create",
    path: "/config/companies/create",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/companies/create.vue").then(m => m.default || m)
  },
  {
    name: "config-companies",
    path: "/config/companies",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/companies/index.vue").then(m => m.default || m)
  },
  {
    name: "config-invoices-create",
    path: "/config/invoices/create",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/invoices/create.vue").then(m => m.default || m)
  },
  {
    name: "config-invoices",
    path: "/config/invoices",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: "config-plans-slug",
    path: "/config/plans/:slug(.*)*",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/plans/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "config-plans-create",
    path: "/config/plans/create",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/plans/create.vue").then(m => m.default || m)
  },
  {
    name: "config-plans",
    path: "/config/plans",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/plans/index.vue").then(m => m.default || m)
  },
  {
    name: "config-products-slug",
    path: "/config/products/:slug(.*)*",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/products/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "config-products-create",
    path: "/config/products/create",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/products/create.vue").then(m => m.default || m)
  },
  {
    name: "config-products",
    path: "/config/products",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/products/index.vue").then(m => m.default || m)
  },
  {
    name: "config-signups-slug",
    path: "/config/signups/:slug(.*)*",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/signups/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "config-signups-create",
    path: "/config/signups/create",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/signups/create.vue").then(m => m.default || m)
  },
  {
    name: "config-signups",
    path: "/config/signups",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/signups/index.vue").then(m => m.default || m)
  },
  {
    name: "config-users-slug",
    path: "/config/users/:slug(.*)*",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/users/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "config-users-create",
    path: "/config/users/create",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/users/create.vue").then(m => m.default || m)
  },
  {
    name: "config-users",
    path: "/config/users",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/users/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app-data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "insights-slug",
    path: "/insights/:slug(.*)*",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/insights/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "insights-activate",
    path: "/insights/activate",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/insights/activate/index.vue").then(m => m.default || m)
  },
  {
    name: "insights-create",
    path: "/insights/create",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/insights/create.vue").then(m => m.default || m)
  },
  {
    name: "insights",
    path: "/insights",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/insights/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginKxKexOSYh0Meta || {},
    component: () => import("/Users/adria/FSBD/Projects-Hub/app-data/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "meta-audiences-process",
    path: "/meta/audiences/process",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app-data/pages/meta/audiences/process.vue").then(m => m.default || m)
  },
  {
    name: "meta-audiences-request",
    path: "/meta/audiences/request",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app-data/pages/meta/audiences/request.vue").then(m => m.default || m)
  },
  {
    name: "meta-interests",
    path: "/meta/interests",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app-data/pages/meta/interests/index.vue").then(m => m.default || m)
  },
  {
    name: "meta-interests-suggestions",
    path: "/meta/interests/suggestions",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app-data/pages/meta/interests/suggestions.vue").then(m => m.default || m)
  },
  {
    name: "plannings-slug",
    path: "/plannings/:slug(.*)*",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/plannings/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "plannings-create",
    path: "/plannings/create",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/plannings/create.vue").then(m => m.default || m)
  },
  {
    name: "plannings",
    path: "/plannings",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/plannings/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacy5aXkPHeJFnMeta || {},
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "Visualización",
    path: "/products/:slug(.*)*",
    meta: _91_46_46_46slug_93WQ6uuLUEveMeta || {},
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/products/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "Productos",
    path: "/products",
    meta: indexWtmDvk6ROHMeta || {},
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: "remember",
    path: "/remember",
    meta: rememberIeVmnrfKBdMeta || {},
    component: () => import("/Users/adria/FSBD/Projects-Hub/app-data/pages/remember.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsgcHdUAmUgoMeta || {},
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/terms.vue").then(m => m.default || m)
  }
]